<template>
  <step-intro-layout :objectives="objectives" @go-to-lesson="goToNextStep"></step-intro-layout>
</template>

<script>
import StepIntroLayout from '@/components/training/StepIntroLayout'
import useTrainingUtils from '@/utils/useTrainingUtils'

export default {
  name: 'Module2Part2Intro',
  components: { StepIntroLayout },
  emits: ['next-step', 'step-finished'],
  setup (props, context) {
    const { goToNextStep } = useTrainingUtils()
    return { goToNextStep }
  },
  data () {
    return {
      objectives: [
        this.$t('module2.part2.intro.objectif1')
      ]
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
